import type { ReactElement } from "react";
import { useState } from "react";
import * as ReactRouterDOM from "react-router-dom";

import DalogLogo from "../../../../assets/svg/DalogLogo";
import { BookmarkButton } from "../BookmarkButton";
import useBookmark from "../Bookmarks/useBookmark";
import { useModeSelect } from "../../../../Components/Home/HomePage/hooks/useModeSelect";
import useOverviewStore from "../../../../Components/MachineCV/MachineCVHomePage/hooks/useOverviewStore";
import type { PowerBi } from "../../../../types";

export type IDashboardCard = {
  id: string;
  name: string;
  subtitle?: string;
  type: PowerBi["parentType"];
  logo?: ReactElement;
  isBookmarked?: boolean;
  slug?: string;
  uniqId?: string;
};
const icons: Record<IDashboardCard["type"], string> = {
  Corporation: "corporation",
  Company: "company",
  Project: "project",
  Machine: "machine",
};

type DashboardCardProps = {
  data: IDashboardCard;
};

export const DashboardCard = ({ data }: DashboardCardProps) => {
  const { id, name, type, slug } = data;
  const { getMarkerNavigationArgs } = useModeSelect();
  const [isLoading, setIsLoading] = useState(false);
  const { toggleBookmark, bookmarks } = useBookmark();
  const addOrRemoveBookmark = () => {
    toggleBookmark({
      bookmark: { ...data, id, type, name, subtitle: data?.subtitle },
      onStart: () => setIsLoading(true),
      onSuccess: () => setIsLoading(false),
      onFailed: () => setIsLoading(false),
    });
  };
  const isBookmarked = !!bookmarks.filter((item: any) => item.id === id)[0];

  const navigate = ReactRouterDOM.useNavigate();
  const { changeSearch } = useOverviewStore();
  const { to, options } = getMarkerNavigationArgs({ id, slug, type });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      className={`DashboardCard__wrapper DashboardCard__icon__${icons[type]}`}
      onClick={() => {
        changeSearch("");
        const dashboardType = type?.toLowerCase() || "project";
        const newTo = `/dashboard/${dashboardType}/${id}`;
        navigate(newTo, options);
      }}
    >
      <div className="DashboardCard__header">
        {data.logo || <DalogLogo />}
        <BookmarkButton
          isBookmarked={isBookmarked}
          isLoading={isLoading}
          onClick={addOrRemoveBookmark}
        />
      </div>

      <div className="DashboardCard__subtitle">{data?.subtitle}</div>
      <div className="DashboardCard__title">{name}</div>
    </div>
  );
};
